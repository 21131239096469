import React from 'react';

const Exportar = () => {
  return (
    <div>
      <h1>Empieza a Exportar</h1>
      {/* Añade aquí el contenido de tu página */}
    </div>
  );
};

export default Exportar;
